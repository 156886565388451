import React from 'react';
import styled from 'styled-components';

import Layout from '../components/layout';

export default props => {
    return (
        <Layout location={props.location}>
            <h1 className="major">Contact</h1>
            Merci pour votre message ! Je vous répondrai au plus vite.
        </Layout>
    );
};
